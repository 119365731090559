.Section7
    background: var(--black)
    border-top: 1px solid var(--bordergrey)
    border-bottom: 1px solid var(--bordergrey)
    &__content
        &__heading
            color: var(--white)
        &__para
            color: var(--white) 
            font-weight: 400 !important
    &__input
        background: var(--white) !important
        display: inline-block
        border-radius: 5px
        font-family: "Open Sans"
    &--small
        font-family: "Open Sans"
        color: var(--textgrey)
        margin-left: -5px
        &--Link
            color: var(--white)
            transition: all 0.3s ease-in-out
            font-family: "Open Sans"
    &--button
        background: var(--black) !important
        color: var(--lightblue)
        font-family: "Open Sans"
        transition: all 0.3s ease-in-out
        margin-left: -9px
        outline: none !important
        border: 0px solid !important
        &:hover
            transform: translateX(10px)
@media only screen and (max-width: 576px)
    .Section7--small
        margin-left: 0px
    .Section7--button
        margin-left: -3px
