.Section7 {
  background: var(--black);
  border-top: 1px solid var(--bordergrey);
  border-bottom: 1px solid var(--bordergrey);
}
.Section7__content__heading {
  color: var(--white);
}
.Section7__content__para {
  color: var(--white);
  font-weight: 400 !important;
}
.Section7__input {
  background: var(--white) !important;
  display: inline-block;
  border-radius: 5px;
  font-family: "Open Sans";
}
.Section7--small {
  font-family: "Open Sans";
  color: var(--textgrey);
  margin-left: -5px;
}
.Section7--small--Link {
  color: var(--white);
  transition: all 0.3s ease-in-out;
  font-family: "Open Sans";
}
.Section7--button {
  background: var(--black) !important;
  color: var(--lightblue);
  font-family: "Open Sans";
  transition: all 0.3s ease-in-out;
  margin-left: -9px;
  outline: none !important;
  border: 0px solid !important;
}
.Section7--button:hover {
  transform: translateX(10px);
}

@media only screen and (max-width: 576px) {
  .Section7--small {
    margin-left: 0px;
  }
  .Section7--button {
    margin-left: -3px;
  }
}/*# sourceMappingURL=style.css.map */