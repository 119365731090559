.Section5
    background: var(--blue)
    &__content
        max-width: 610px
        width: 100%
        &__heading
            color: var(--white)
            white-space: nowrap
        &__para
            color: var(--white)
        &--button
            outline: none !important
            border: 0px solid 
            background: #4BAAE2
            max-width: 350px
            width: 100%
            border-radius: 50px
            height: 47px
            transition: all 0.3s ease-in-out
            &--Link
                color: var(--white)
                font-family: "Open Sans"
                font-size: 18px
            &:hover
                background: var(--bgblue)
                border: 0px solid var(--bgblue)
                transform: scale(1.05)
            &:hover .Section5__content--button--Link
                color: var(--white)
    &--img
        max-width: 400px
        width: 100%


@media only screen and (max-width: 1400px)
    .Section5__content
        max-width: 550px
@media only screen and (max-width: 767px)
    .Section5__content
        max-width: 470px
    .Section5__content__heading
        white-space: normal
@media only screen and (max-width: 576px)
    .Section5__content
        max-width: 387px