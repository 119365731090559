.Section2 {
  background: var(--lightpurple);
}
.Section2__left--img {
  max-width: 500px;
  width: 100% !important;
}
.Section2__content__heading {
  color: var(--darkgrey);
}
.Section2__content__heading--blue {
  color: var(--blue);
}
.Section2__content__para--bold {
  font-weight: 700;
}

@media only screen and (max-width: 576px) {
  .Section2__left {
    padding: 0px !important;
  }
}/*# sourceMappingURL=style.css.map */