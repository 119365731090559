.Industry
    &__card
        transition: all 0.3s ease-in-out
        cursor: pointer
        background: var(--softblue)
        &__top
            width: 100%
            height: 250px
            overflow: hidden
            &--img
                object-fit: cover
                width: 100%
                height: 100%
        &__content
            &__heading
                font-family: 'Merriweather'
                color: var(--darkblue)
                font-weight: bold
                font-size: 20px
                line-height: 25px
            &__para
                color: var(--darkgrey)
        &:hover
            box-shadow: rgb(56, 56 ,57, 15%) 0px 10px 30px
@media only screen and (max-width: 1400px)
    .Industry__card__content__heading
        font-size: 18px
        line-height: 23px
    .Industry__card__top
        height: 200px
@media only screen and (max-width: 1200px)
    .Industry__card__content__heading
        font-size: 16px
        line-height: 21px
    .Industry__card__top
        height: 170px
@media only screen and (max-width: 400px)
    .Industry__card__top
        height: 150px
    .Industry__card__content__heading
        font-size: 15px
        line-height: 20px
    .Industry__card__content__heading5
        word-break: break-all
