.Value
    background: var(--green)
    &__heading
        color: var(--white)
        font-weight: 700
        font-size: 70px
        font-family: 'Merriweather'
    &__card
        &1, &2
            border-right: 1px solid #ffffff62
        &__top
            height: 240px
            &--img
                width: 200px
                &1
                    margin-left: -19px
                &2
                    margin-left: -14px
                &3
                    margin-left: -1px
        &__title, &__para
            color: var(--white)
            font-size: 20px
            font-family: 'Open Sans'
            line-height: 28px
@media only screen and (max-width: 1400px)
    .Value__heading
        font-size: 60px
    .Value__card__top
        height: 180px
    .Value__card__top--img
        width: 150px
        &1
            margin-left: -15px
        &2
            margin-left: -10px
    .Value__card__title, .Value__card__para
        font-size: 18px
        line-height: 25px
@media only screen and (max-width: 1200px)
    .Value__heading
        font-size: 50px
    .Value__card__top
        height: 150px
    .Value__card__top--img
        width: 130px
        &1
            margin-left: -12px
        &2
            margin-left: -8px
@media only screen and (max-width: 992px)
    .Value__heading
        font-size: 40px
    .Value__card2
        border-right: 0px solid
    .Value__card__top
        height: 150px
    .Value__card__top--img
        width: 130px
        &1
            margin-left: -12px
        &2
            margin-left: -8px
    .Value__card__title, .Value__card__para
        font-size: 18px
        line-height: 25px
@media only screen and (max-width: 576px)
    .Value__heading
        font-size: 32px
    .Value__card1
        border-right: 0px solid
    .Value__card__top
        height: 125px
    .Value__card__top--img
        width: 110px
        &1
            margin-left: -12px
        &2
            margin-left: -8px
    .Value__card__title, .Value__card__para
        font-size: 16px
        line-height: 23px
@media only screen and (max-width: 390px)
    .Value__heading
        font-size: 30px