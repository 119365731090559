.Industry__card {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: var(--softblue);
}
.Industry__card__top {
  width: 100%;
  height: 250px;
  overflow: hidden;
}
.Industry__card__top--img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.Industry__card__content__heading {
  font-family: "Merriweather";
  color: var(--darkblue);
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
}
.Industry__card__content__para {
  color: var(--darkgrey);
}
.Industry__card:hover {
  box-shadow: rgba(56, 56, 57, 0.15) 0px 10px 30px;
}

@media only screen and (max-width: 1400px) {
  .Industry__card__content__heading {
    font-size: 18px;
    line-height: 23px;
  }
  .Industry__card__top {
    height: 200px;
  }
}
@media only screen and (max-width: 1200px) {
  .Industry__card__content__heading {
    font-size: 16px;
    line-height: 21px;
  }
  .Industry__card__top {
    height: 170px;
  }
}
@media only screen and (max-width: 400px) {
  .Industry__card__top {
    height: 150px;
  }
  .Industry__card__content__heading {
    font-size: 15px;
    line-height: 20px;
  }
  .Industry__card__content__heading5 {
    word-break: break-all;
  }
}/*# sourceMappingURL=style.css.map */