.banner2 {
  height: 650px;
  width: 100%;
}
.banner2-absolute {
  height: 650px;
  z-index: 8;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0px;
}
.banner2__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.banner2__content {
  max-width: 1001px;
  height: 100%;
  z-index: 9;
}
.banner2__content__heading {
  font-family: "Merriweather";
  letter-spacing: 1px;
  color: var(--white);
  font-size: 71px;
  line-height: 80px;
  font-weight: 700;
}
.banner2__content__para {
  font-family: "Open Sans";
  color: var(--white);
  font-size: 24px;
  max-width: 79% !important;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0px;
}

@media only screen and (max-width: 1400px) {
  .banner2 {
    height: 500px;
  }
  .banner2-absolute {
    height: 500px;
  }
  .banner2__content {
    max-width: 870px;
  }
  .banner2__content__heading {
    font-size: 60px;
    line-height: 60px;
  }
  .banner2__content__para {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 1200px) {
  .banner2 {
    height: 470px;
  }
  .banner2-absolute {
    height: 470px;
  }
  .banner2__content {
    max-width: 700px;
  }
  .banner2__content__heading {
    font-size: 55px;
    line-height: 65px;
  }
  .banner2__content__para {
    font-size: 20px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 992px) {
  .banner2 {
    height: 450px;
  }
  .banner2-absolute {
    height: 450px;
  }
  .banner2__content {
    height: 100%;
    max-width: 700px;
  }
  .banner2__content__heading {
    font-size: 50px;
    line-height: 60px;
  }
  .banner2__content__para {
    font-size: 18px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .banner2__content {
    max-width: 547px;
  }
  .banner2__content__heading {
    font-size: 43px;
    line-height: 53px;
  }
  .banner2__content__para {
    max-width: 70% !important;
  }
}
@media only screen and (max-width: 576px) {
  .banner2 {
    height: 410px;
  }
  .banner2-absolute {
    height: 410px;
  }
  .banner2-absolute {
    background-position: top center;
  }
  .banner2__content {
    max-width: 500px;
  }
  .banner2__content__heading {
    font-size: 40px;
    line-height: 50px;
  }
  .banner2__content__para {
    font-size: 16px;
    line-height: 22px;
    max-width: 85% !important;
  }
}
@media only screen and (max-width: 450px) {
  .banner2__content__heading {
    font-size: 37px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 380px) {
  .banner2__content {
    max-width: 300px;
  }
  .banner2__content__heading {
    font-size: 30px;
    line-height: 40px;
  }
}/*# sourceMappingURL=style.css.map */