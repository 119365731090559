.Thin {
  background: var(--darkblue);
}
.Thin--Link {
  font-family: "Open Sans";
  font-size: 14px;
}

@media only screen and (max-width: 330px) {
  .Thin--Link {
    font-size: 13px;
    line-height: 25px;
  }
}/*# sourceMappingURL=style.css.map */