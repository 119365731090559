.Section5 {
  background: var(--blue);
}
.Section5__content {
  max-width: 610px;
  width: 100%;
}
.Section5__content__heading {
  color: var(--white);
  white-space: nowrap;
}
.Section5__content__para {
  color: var(--white);
}
.Section5__content--button {
  outline: none !important;
  border: 0px solid;
  background: #4BAAE2;
  max-width: 350px;
  width: 100%;
  border-radius: 50px;
  height: 47px;
  transition: all 0.3s ease-in-out;
}
.Section5__content--button--Link {
  color: var(--white);
  font-family: "Open Sans";
  font-size: 18px;
}
.Section5__content--button:hover {
  background: var(--bgblue);
  border: 0px solid var(--bgblue);
  transform: scale(1.05);
}
.Section5__content--button:hover .Section5__content--button--Link {
  color: var(--white);
}
.Section5--img {
  max-width: 400px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .Section5__content {
    max-width: 550px;
  }
}
@media only screen and (max-width: 767px) {
  .Section5__content {
    max-width: 470px;
  }
  .Section5__content__heading {
    white-space: normal;
  }
}
@media only screen and (max-width: 576px) {
  .Section5__content {
    max-width: 387px;
  }
}/*# sourceMappingURL=style.css.map */