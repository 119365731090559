.banner {
  height: 100vh;
  overflow: hidden;
  z-index: 8;
  position: absolute;
  top: 0px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
}
.banner__image {
  position: absolute;
  top: 0px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}
.banner__image--img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100vh;
}
.banner__content {
  max-width: 850px;
  height: 100%;
  z-index: 2;
}
.banner__content__heading {
  font-family: "Merriweather";
  letter-spacing: 1px;
  color: var(--white);
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
}
.banner__content__para {
  color: var(--white);
  font-size: 21px;
  max-width: 73% !important;
  line-height: 27px;
  font-weight: 300;
  letter-spacing: 0px;
}
.banner__content__links--Link {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  transition: all 0.3s ease-out;
}
.banner__content__links--Link__icon {
  font-size: 32px !important;
}
.banner__content__links--Link:hover {
  color: var(--white);
}
.banner__content__links--Link1 {
  color: var(--golden);
}
.banner__content__links--Link2 {
  color: var(--green);
}

.Scroll {
  position: absolute;
  left: 0;
  bottom: 20px;
  color: var(--golden);
}

.Scroll__icon:hover {
  color: white;
  transform: scale(1.5);
}

.Scroll__top {
  color: white;
}

.Scroll__top:hover {
  color: var(--golden);
  transform: scale(1.5);
}

@media only screen and (max-width: 1400px) {
  .banner__content {
    max-width: 855px;
  }
  .banner__content__heading {
    font-size: 63px;
    line-height: 75px;
  }
  .banner__content__para {
    font-size: 18px;
    max-width: 62% !important;
  }
  .banner__content__links--Link {
    font-size: 16px;
  }
  .banner__content__links--Link__icon {
    font-size: 25px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .banner__content__links--Link2 {
    margin-left: 5px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .Scroll {
    left: 30px;
    bottom: 30px;
  }
}
@media only screen and (max-width: 1050px) {
  .banner__content {
    max-width: 620px;
  }
  .banner__content__heading {
    font-size: 55px;
    line-height: 67px;
  }
  .banner__content__para {
    font-size: 19px;
    line-height: 26px;
    max-width: 89% !important;
  }
  .banner__content__links--Link2 {
    margin-left: 5px !important;
  }
}
@media only screen and (max-width: 992px) {
  .banner__content {
    margin-top: 170px;
  }
  .Scroll {
    left: 30px;
    bottom: 200px;
  }
}
@media only screen and (max-width: 800px) {
  .banner__content {
    max-width: 620px;
  }
  .banner__content__heading {
    font-size: 50px;
    line-height: 65px;
  }
  .banner__content__para {
    max-width: 79% !important;
  }
}
@media only screen and (max-width: 700px) {
  .banner__content {
    max-width: 547px;
  }
  .banner__content__heading {
    font-size: 45px;
    line-height: 60px;
  }
  .banner__content__para {
    max-width: 90% !important;
  }
  .banner__content__links--Link2 {
    margin-left: 5px !important;
  }
}
@media only screen and (max-width: 600px) {
  .banner__content {
    max-width: 547px;
  }
  .banner__content__heading {
    font-size: 40px;
    line-height: 55px;
  }
  .banner__content__para {
    max-width: 90% !important;
  }
  .banner__content__links--Link {
    font-size: 16px;
  }
  .banner__content__links--Link__icon {
    font-size: 25px !important;
    margin-top: 1px;
  }
  .banner__content__links--Link2 {
    margin-left: 5px !important;
  }
}
@media only screen and (max-width: 576px) {
  .banner__content {
    max-width: 423px;
  }
  .banner__content__heading {
    font-size: 46px;
    line-height: 57px;
  }
  .banner__content__para {
    max-width: 90% !important;
    font-size: 16px;
    line-height: 22px;
  }
  .banner__content__links--Link {
    font-size: 18px;
  }
  .banner__content__links--Link__icon {
    font-size: 35px !important;
    margin-top: 1px;
  }
  .banner__content__links--Link2 {
    margin-left: 0px !important;
  }
  .Scroll {
    left: 30px;
    bottom: 190px;
  }
}
@media only screen and (max-width: 500px) {
  .banner__content {
    max-width: 423px;
    margin-top: 140px !important;
  }
  .banner__content__heading {
    font-size: 40px;
    line-height: 50px;
  }
  .Scroll {
    left: 30px;
    bottom: 160px;
  }
}
@media only screen and (max-width: 450px) {
  .banner__content {
    max-width: 366px;
    margin-top: 130px !important;
  }
  .banner__content__heading {
    font-size: 35px;
    line-height: 45px;
  }
  .Scroll {
    left: 30px;
    bottom: 150px;
  }
}
@media only screen and (max-width: 380px) {
  .banner__content {
    max-width: 300px;
    margin-top: 120px !important;
  }
  .banner__content__heading {
    font-size: 30px;
    line-height: 40px;
  }
  .Scroll {
    left: 30px;
    bottom: 140px;
  }
}/*# sourceMappingURL=style.css.map */