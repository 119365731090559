.Section1__content {
  max-width: 1065px;
}
.Section1__content__heading {
  font-family: "Merriweather";
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
}
.Section1__content__heading--darkgrey {
  color: var(--darkgrey);
}
.Section1__content__heading--blue {
  color: var(--blue);
}
.Section1__content__para {
  color: var(--darkblue);
  font-size: 22px;
  line-height: 30px;
  font-family: "Open Sans";
  max-width: 1001px;
}
.Section1__content--bold {
  font-weight: 700;
  font-style: italic;
}

@media only screen and (max-width: 1400px) {
  .Section1__content {
    max-width: 886px;
  }
  .Section1__content__heading {
    font-size: 52px;
    line-height: 61px;
  }
  .Section1__content__para {
    font-size: 20px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 1300px) {
  .Section1__content {
    max-width: 859px;
  }
  .Section1__content__heading {
    font-size: 53px;
    line-height: 62px;
  }
  .Section1__content__para {
    font-size: 19px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 1050px) {
  .Section1__content {
    max-width: 819px;
  }
  .Section1__content__heading {
    font-size: 50px;
    line-height: 59px;
  }
  .Section1__content__para {
    font-size: 18px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 992px) {
  .Section1__content {
    max-width: 713px;
  }
  .Section1__content__heading {
    font-size: 57px;
    line-height: 66px;
  }
  .Section1__content__heading--blue {
    color: var(--darkgrey);
  }
  .Section1__content__heading--darkgrey {
    color: var(--blue);
  }
  .Section1__content__para {
    font-size: 18px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 870px) {
  .Section1__content {
    max-width: 665px;
  }
  .Section1__content__heading {
    font-size: 54px;
    line-height: 65px;
  }
  .Section1__content__para {
    font-size: 18px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .Section1__content {
    max-width: 631px;
  }
  .Section1__content__heading {
    font-size: 47px;
    line-height: 54px;
  }
  .Section1__content__para {
    font-size: 17px;
    line-height: 23px;
  }
}
@media only screen and (max-width: 676px) {
  .Section1__content {
    max-width: 520px;
  }
  .Section1__content__heading {
    font-size: 42px;
    line-height: 49px;
  }
  .Section1__content__para {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 576px) {
  .Section1__content {
    max-width: 490px;
    margin-top: -25px !important;
  }
  .Section1__content__heading {
    font-size: 37px;
    line-height: 44px;
  }
}
@media only screen and (max-width: 500px) {
  .Section1__content {
    max-width: 420px;
  }
  .Section1__content__heading {
    font-size: 32px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 390px) {
  .Section1__content__heading {
    font-size: 30px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 367px) {
  .Section1__content__heading {
    font-size: 29px;
    line-height: 36px;
  }
}/*# sourceMappingURL=style.css.map */