.Navbar
    position: fixed
    width: 100%
    z-index: 1000
    &__transparent
        background: #00000000
    &__black
        background: black
    &__logo
        width: 180px
@media only screen and (max-width: 992px)
    .Navbar__logo
        width: 150px
@media only screen and (max-width: 576px)
    .Navbar__logo
        width: 120px