.Terms__container {
  max-width: 900px;
}
.Terms__container__para {
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 23px;
  color: var(--darkblue);
}
.Terms__container__heading {
  font-size: 26px;
  font-family: "Open Sans";
  color: var(--green);
  line-height: 26px;
}

@media only screen and (max-width: 1400px) {
  .Terms__container {
    max-width: 700px;
  }
  .Terms__container__para {
    font-size: 14px;
    line-height: 21px;
  }
  .Terms__container__heading {
    font-size: 24px;
    line-height: 24px;
  }
}/*# sourceMappingURL=style.css.map */