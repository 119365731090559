.Section2
    background: var(--lightpurple)
    &__left
        &--img
            max-width: 500px
            width: 100% !important
    &__content
        &__heading
            color: var(--darkgrey)
            &--blue
                color: var(--blue)
        &__para
            &--bold
                font-weight: 700
@media only screen and (max-width: 992px)
    // .Section2
    //     padding: 0px !important
@media only screen and (max-width: 576px)
    .Section2__left
        padding: 0px !important
            
