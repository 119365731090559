@import './fonts/Merriweather/stylesheet.css';
@import './fonts/Open_Sans/stylesheet.css';
@import './fonts/Open_Sans/static/OpenSans/stylesheet.css';
@import './fonts/Open_Sans/static/OpenSans_Condensed/stylesheet.css';
@import './fonts/Open_Sans/static/OpenSans_SemiCondensed/stylesheet.css';

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
 * * ========================================================================== */
/**
 * * Remove the margin in all browsers. */
body {
  margin: 0;
  overflow: auto !important;
  padding: 0px;
}

/**
 * * Render the `main` element consistently in IE. */
main {
  display: block;
}

/**
 * * Correct the font size and margin on `h1` elements within `section` and
 * * `article` contexts in Chrome, Firefox, and Safari. */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * * ========================================================================== */
/**
 * * 1. Add the correct box sizing in Firefox.
 * * 2. Show the overflow in Edge and IE. */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * * 1. Correct the inheritance and scaling of font size in all browsers.
 * * 2. Correct the odd `em` font sizing in all browsers. */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * * ========================================================================== */
/**
 * * Remove the gray background on active links in IE 10. */
a {
  background-color: transparent;
}

/**
 * * 1. Remove the bottom border in Chrome 57-
 * * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * * Add the correct font weight in Chrome, Edge, and Safari. */
b,
strong {
  font-weight: bolder;
}

/**
 * * 1. Correct the inheritance and scaling of font size in all browsers.
 * * 2. Correct the odd `em` font sizing in all browsers. */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * * Add the correct font size in all browsers. */
small {
  font-size: 80%;
}

/**
 * * Prevent `sub` and `sup` elements from affecting the line height in
 * * all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
 * * ========================================================================== */
/**
 * * Remove the border on images inside links in IE 10. */
img {
  border-style: none;
}

/* Forms
 * * ========================================================================== */
/**
 * * 1. Change the font styles in all browsers.
 * * 2. Remove the margin in Firefox and Safari. */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * * Show the overflow in IE.
 * * 1. Show the overflow in Edge. */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * * 1. Remove the inheritance of text transform in Firefox. */
button,
select {
  /* 1 */
  text-transform: none;
}

/**

/**
 * * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * * Correct the padding in Firefox. */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * * 1. Correct the text wrapping in Edge and IE.
 * * 2. Correct the color inheritance from `fieldset` elements in IE.
 * * 3. Remove the padding so developers are not caught out when they zero out
 * *    `fieldset` elements in all browsers. */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * * Add the correct vertical alignment in Chrome, Firefox, and Opera. */
progress {
  vertical-align: baseline;
}

/**
 * * Remove the default vertical scrollbar in IE 10+. */
textarea {
  overflow: auto;
}

/**
 * * 1. Add the correct box sizing in IE 10.
 * * 2. Remove the padding in IE 10. */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * * Correct the cursor style of increment and decrement buttons in Chrome. */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/* Interactive
 * * ========================================================================== */
/* * Add the correct display in Edge, IE 10+, and Firefox. */
details {
  display: block;
}

/* * Add the correct display in all browsers. */
summary {
  display: list-item;
}

/* Misc
 * * ========================================================================== */
/**
 * * Add the correct display in IE 10+. */
template,
[hidden] {
  display: none;
}

/**
 * * Add the correct display in IE 10. */
/** CUSTOM CSS STARTS HERE **/
*::-moz-selection {
  background: #0E1F50;
  color: white;
}

*::selection {
  background: #0E1F50;
  color: white;
}

:root {
  --golden: #F7B223;
  --green: #00A099;
  --blue: #005FBE;
  --darkblue: #0E1F50;
  --skyblue: #00ABE71A;
  --darkgrey: #363636;
  --black: #000000;
  --white: #FFFFFF;
  --lightpurple: #F5F5F6;
  --bgblue: #091331;
  --textgrey: #969696;
  --lightblue: #00ABE7;
  --bordergrey: #494F4F;
  --lightgrey: #D8D8DA40;
  --softblue: #00ABE70A;
  --lightborder: #C4C4C4;
  --clients: #005FBE26;
  --experts: #00A0991A;
  --bggrey: #D8D8DA1A;
  --bordergrey: #7070703a;
  --mapbg: #F2FAFD;
  --lightblue3: #00ABE70D;
  --red: #DE0009;
}
.nowrap{
  white-space: nowrap;
}
.green {
  color: var(--green) !important;
}
.bg-green {
  background: var(--green) !important;
}
.darkblue {
  color: var(--darkblue) !important;
}
.bg-darkblue {
  background: var(--darkblue) !important;
}
.red {
  color: var(--red) !important;
}

.golden {
  color: var(--golden) !important;
}
.bg-golden {
  background: var(--golden) !important;
}
.lightgrey {
  color: var(--black) !important;
  opacity: 0.4;
}

.dotted-border {
  border-bottom: 2px solid #d8d8da;
  border-bottom-style: dotted;
}

.border-Left {
  border-left: 1px solid var(--bordergrey);
}

.border-Top {
  border-top: 1px solid var(--bordergrey);
}

.border-Bottom {
  border-bottom: 1px solid var(--bordergrey);
}

.border-Right {
  border-right: 1px solid var(--bordergrey);
}

.fm {
  font-family: "Open Sans" !important;
}

html {
  background: var(--grey);
  font-family: 'Open Sans';
}

.banner-height {
  height: 98vh;
  z-index: 0;
  position: relative;
}

.show-more {
  cursor: pointer;
}
.transition{
  transition: all 0.3s ease-in-out;
}
.show-more-title,
.show-more-icon {
  color: var(--darkblue);
  transition: all 0.3s ease-in-out;
}
.blue_btn{
  background-color: #005FBE;
  font-family: 'Open Sans';
  color: white;
  border: 0px solid;
  transition: all 0.3s ease-in-out;
}
.blue_btn:hover, .yellow_btn:hover, .zinc_btn:hover {
  background-color: #0E1F50;
}
.yellow_btn{
  background-color: #F9BA00;
  font-family: 'Open Sans';
  color: white;
  border: 0px solid;
  transition: all 0.3s ease-in-out;
}
.zinc_btn{
  background-color: #00A099;
  font-family: 'Open Sans';
  color: white;
  border: 0px solid;
  transition: all 0.3s ease-in-out;
}
.pointer{
  cursor: pointer;
}

.CookieConsent {
  align-items: center!important;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 999999!important;
}

.CookieConsent button#rcc-confirm-button {
  width: 170px;
  height: 43px;
  background: transparent !IMPORTANT;
  border: 1px solid #005fbe !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  padding-top: 2px !important;
}

.CookieConsent button#rcc-confirm-button:hover {
  background-color: #005fbe !important;
  color: #000 !important;
}

.CookieConsent>div:first-child {
  width: 70% !important;
  flex: none !important;
  font-size: 13px;
}

.CookieConsent h4 {
  color: #005fbe;
}


@media only screen and (max-width: 1200px) {
  .main-padding {
    padding: 0px 30px;
  }
}

@media only screen and (max-width: 992px) {
  .banner-height {
    height: 110vh;
  }

  .border-Left,
  .border-Right {
    border: 0px solid;
  }
}

@media only screen and (max-width: 576px) {
  .main-padding {
    padding: 0px 15px !important;
  }
}