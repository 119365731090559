.Navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.Navbar__transparent {
  background: rgba(0, 0, 0, 0);
}
.Navbar__black {
  background: black;
}
.Navbar__logo {
  width: 180px;
}

@media only screen and (max-width: 992px) {
  .Navbar__logo {
    width: 150px;
  }
}
@media only screen and (max-width: 576px) {
  .Navbar__logo {
    width: 120px;
  }
}/*# sourceMappingURL=style.css.map */