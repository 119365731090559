.Small
    background: var(--lightgrey)
    &__card
        &__top
            &--img
                height: 75px
        &__para
            color: var(--blue) !important
            font-weight: normal
            font-family: 'Open Sans'
@media only screen and (max-width: 1400px)
    .Small__card__top--img
        height: 50px
@media only screen and (max-width: 576px)
    .Small__card__para
        font-size: 16px
        line-height: 21px
@media only screen and (max-width: 400px)
    .Small__card__top--img
        height: 45px
