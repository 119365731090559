.Terms
    &__container
        max-width: 900px
        &__para
            font-family: 'Open Sans'
            font-size: 16px
            line-height: 23px
            color: var(--darkblue)
        &__heading
            font-size: 26px
            font-family: 'Open Sans'
            color: var(--green)
            line-height: 26px
@media only screen and (max-width: 1400px)
    .Terms
        &__container
            max-width: 700px
            &__para
                font-size: 14px
                line-height: 21px
            &__heading
                font-size: 24px
                line-height: 24px
