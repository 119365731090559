.Services
    background: var(--lightgrey)
    &__row
        border-bottom: 1px solid #2560B8
    &__heading
        color: var(--darkblue)
        font-weight: 700
        font-size: 70px
        font-family: 'Merriweather'
    &__card
        &1, &2
            border-right: 1px solid #0E62BB
            border-right-style: dotted
        &__top
            height: 240px
            &--img
                height: 210px
                &1
                    margin-left: -9px
                &2
                    margin-left: -14px
                &3
                    margin-left: -5px
        &__title, &__para
            color: var(--darkblue)
            font-size: 20px
            font-family: 'Open Sans'
            line-height: 28px
        &__title
            font-weight: bold
@media only screen and (max-width: 1400px)
    .Services__heading
        font-size: 60px
    .Services__card__top
        height: 180px
    .Services__card__top--img
        height: 170px
    .Services__card__title, .Services__card__para
        font-size: 18px
        line-height: 25px
@media only screen and (max-width: 1200px)
    .Services__heading
        font-size: 50px
    .Services__card__top
        height: 150px
    .Services__card__top--img
        height: 150px
        &1
            margin-left: 0px
        &2
            margin-left: -8px
@media only screen and (max-width: 992px)
    .Services__heading
        font-size: 40px
    .Services__card2
        border-right: 0px solid
    .Services__card__top
        height: 150px
    .Services__card__top--img
        height: 130px
        &1
            margin-left: 0px
        &2
            margin-left: -8px
    .Services__card__title, .Services__card__para
        font-size: 18px
        line-height: 25px
@media only screen and (max-width: 576px)
    .Services__heading
        font-size: 32px
    .Services__card1
        border-right: 0px solid
    .Services__card__top
        height: 130px
    .Services__card__top--img
        height: 110px
        &1
            margin-left: 0px
        &2
            margin-left: -7px
    .Services__card__title, .Services__card__para
        font-size: 16px
        line-height: 23px
@media only screen and (max-width: 390px)
    .Services__heading
        font-size: 30px
