.Demo
    min-height: 94vh
    &__heading
        color: var(--green)
        line-height: 71px !important
    &__para
        color: var(--darkblue)
        font-weight: 400
.calendly-inline-widget
    min-width: 290px !important
    max-width: 1100px !important
    width: 100% !important
    min-height: 690px !important
@media only screen and (max-width: 1400px)
    .Demo__heading
        line-height: 60px !important
@media only screen and (max-width: 1200px)
    .Demo__heading
        line-height: 55px !important
@media only screen and (max-width: 1060px)
    .calendly-inline-widget
        min-height: 960px !important
@media only screen and (max-width: 992px)
    .Demo__heading
        line-height: 50px !important
@media only screen and (max-width: 768px)
    .Demo__heading
        line-height: 43px !important
@media only screen and (max-width: 709px)
    .calendly-inline-widget
        min-height: 800px !important
@media only screen and (max-width: 576px)
    .Demo__heading
        line-height: 40px !important
@media only screen and (max-width: 549px)
    .calendly-inline-widget
        min-height: 780px !important
    .Demo__para
        max-width: 100% !important
@media only screen and (max-width: 450px)
    .Demo__heading
        line-height: 37px !important
@media only screen and (max-width: 380px)
    .Demo__heading
        line-height: 30px !important
@media only screen and (max-width: 350px)
    .Demo__heading
        font-size: 27px !important
        line-height: 27px !important
    .calendly-inline-widget
        min-height: 820px !important
